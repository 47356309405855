<template>
  <BaseModal
    name="importar-dados"
    id="importar-dados"
    idModal="importar-dados"
    size="xl"
    :title="title"
    help="https://gdigital.crisp.help/pt-br/article/como-importar-leads-srxkck/"
    @shown="openModal"
  >
    <div v-if="!loading">
      <b-col v-if="!importacao || importacao.status != 'done'" cols="12">
        <div class="top-dados"></div>
        <b-form data-vv-name="nome" v-if="steps == 1">
          <div>
            <div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span style="font-size: 13px"
                  >Limite de importação: {{ max_leads * 3 }} Leads</span
                >
                <BaseButton
                  @click="avanacdoToggle"
                  variant="link-info"
                  class="mr-4"
                >
                  Avançado
                </BaseButton>
              </div>
              <b-form-group>
                <label class="mt-3">Nome da lista</label>
                <input
                  class="w-100"
                  type="text"
                  name="lista"
                  id="lista"
                  placeholder="Minha lista"
                  v-model="lista"
                />
                <label>Selecione o arquivo</label>
                <input
                  class="w-100"
                  type="file"
                  name="csv-input"
                  id="csv-input"
                  accept=".csv"
                />
                <span class="span-csv">Aceita apenas CSV.</span>
                <b-form-group class="avancado" v-if="avancado">
                  <div class="add-link-body">
                    <label class="mr-1">Vendedor</label>
                    <router-link
                      v-if="
                        sellers.length === 1 &&
                        stops.includes('seller') &&
                        canShowOption()
                      "
                      :to="{ name: 'Usuarios' }"
                      class="add-link"
                      >Deseja criar um vendedor?</router-link
                    >
                  </div>
                  <BaseSelect
                    :selectModel="selectedSeller"
                    placeholder="Selecione um vendedor"
                    trackname="email"
                    track-by="id"
                    :array="sellers"
                    :multiple="false"
                    :taggable="true"
                    class=""
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel=""
                    @callback="getVendedor($event)"
                    @change="selectedSeller = $event"
                    ><span slot="noResult"
                      >Nenhum dado encontrado.</span
                    ></BaseSelect
                  >
                  <div class="add-link-body">
                    <label class="mt-3">Formulário de inscrição</label>
                    <router-link
                      v-if="
                        !forms.length &&
                        stops.includes('form') &&
                        verificUserLevel
                      "
                      :to="{ name: 'CampanhasLista' }"
                      class="add-link"
                      >Deseja criar um formulário?</router-link
                    >
                  </div>
                  <BaseSelect
                    :selectModel="selectedForm"
                    placeholder="Selecione o formulário"
                    track-by="id"
                    :array="forms"
                    :multiple="false"
                    :searchable="true"
                    :taggable="true"
                    class=""
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel=""
                    @search="debounce($event)"
                    @callback="getForms($event)"
                    @change="selectedForm = $event"
                    :loading="loading_select"
                    ><span slot="noResult">Nenhum formulário encontrado.</span>
                  </BaseSelect>
                  <div class="add-link-body mt-3">
                    <label class="mr-1">Tags</label>
                    <router-link
                      v-if="
                        !tags.length &&
                        stops.includes('tag') &&
                        verificUserLevel
                      "
                      :to="{ name: 'Tags' }"
                      class="add-link"
                      >Deseja criar uma tag?</router-link
                    >
                  </div>
                  <BaseSelect
                    :selectModel="selectedTags"
                    placeholder="Selecione tags"
                    trackname="name"
                    specificType="tags"
                    track-by="id"
                    :array="tags"
                    :multiple="true"
                    :taggable="true"
                    class=""
                    deselectLabel=""
                    selectedLabel=""
                    openDirection="bottom"
                    @callback="getTags($event)"
                    @change="selectedTags = $event"
                    ><span slot="noResult"
                      >Nenhum dado encontrado.</span
                    ></BaseSelect
                  >
                  <label class="mt-3">E-mail para notificar a importação</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    class="w-100"
                    placeholder="Digite seu e-mail"
                    v-model="email"
                  />
                </b-form-group>
              </b-form-group>
            </div>
          </div>
        </b-form>

        <b-form v-if="steps == 2">
          <div>
            <div>
              <div class="mb-3">
                <p style="max-width: 80%">
                  Confirme os dados abaixo para seguirmos com a importação.
                  Verifique se os dados do campo corresponde ao campo
                  pré-selecionado
                </p>
              </div>
              <b-row class="Table-header mb-1">
                <b-col cols="4" class="d-none gap-label d-md-block">
                  Dados do campo
                </b-col>

                <b-col cols="4" class="d-none gap-label d-md-block">
                  Este campo é um:
                </b-col>

                <b-col
                  v-if="selected.indexOf('adicional') != -1"
                  cols="4"
                  class="d-none gap-label d-md-block"
                >
                  Nome do campo
                </b-col>
              </b-row>

              <b-row
                v-for="(item, index) in rawSample"
                :key="index"
                class="Table-body mb-1"
              >
                <b-col cols="4" class="d-none gap-label d-md-block">
                  {{ item }}
                </b-col>

                {{ setAdicional(selected[index], index) }}

                <b-col cols="4" class="d-none gap-label d-md-block">
                  <b-form-select
                    :options="opcoes"
                    v-model="selected[index]"
                    @change="
                      selected[index] === 'adicional'
                        ? (adicional[index] = '')
                        : (adicional[index] = null)
                    "
                  ></b-form-select>
                </b-col>

                <b-col
                  v-if="selected[index] == 'adicional'"
                  cols="4"
                  class="d-none gap-label d-md-block"
                >
                  <input
                    type="text"
                    name="adicional"
                    id="adicional"
                    placeholder="Nome do campo"
                    v-model="adicional[index]"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>

        <b-form v-if="steps == 3">
          <div>
            <div>
              <div class="mb-3">
                <p style="max-width: 80%">
                  Confirme os dados abaixo para seguirmos com a importação.
                  Verifique se os dados do campo corresponde ao campo
                  pré-selecionado
                </p>
              </div>
              <span class="span-csv">Exemplos da configuração</span>
              <div class="ul-container" v-if="sample">
                <div v-for="(item, index) in removeFirst" :key="index">
                  <div>
                    <ul class="exemplo-item">
                      <div>
                        <li
                          v-for="(item2, index) in sample[index + 1]"
                          :key="index"
                        >
                          <div class="content">
                            <span>{{ index }}</span>
                            <p>{{ item2 }}</p>
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </b-col>

      <b-col v-if="importacao && importacao.status == 'done'">
        <div class="box-total">
          <div class="total-vendas info"></div>
          <div>
            <p class="total-vendas">
              Total de leads:
              <span>{{ total }}</span>
            </p>
          </div>
          <div></div>
        </div>
        <div class="header">
          <b-dropdown id="filterLeads" left style="margin-top: 12px">
            <template id="button-filter" #button-content>
              <svg
                v-if="itemSelected == 'circle'"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="var(--bluee)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-circle"
              >
                <circle cx="12" cy="12" r="10"></circle>
              </svg>
              <svg
                v-else-if="itemSelected == 'poligono'"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="var(--bluee)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-octagon"
              >
                <polygon
                  points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"
                ></polygon>
              </svg>
              <svg
                v-else-if="itemSelected == 'square'"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="var(--bluee)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-square"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
              </svg>
              <svg
                v-else-if="itemSelected == 'lead-check'"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="var(--bluee)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-check-square"
              >
                <polyline points="9 11 12 14 22 4"></polyline>
                <path
                  d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"
                ></path>
              </svg>
              <svg
                v-else-if="itemSelected == 'lead-update'"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="var(--bluee)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-minus-square"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="8" y1="12" x2="16" y2="12"></line>
              </svg>
              <svg
                v-else-if="itemSelected == 'lead-error'"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="var(--bluee)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x-square"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="9" y1="9" x2="15" y2="15"></line>
                <line x1="15" y1="9" x2="9" y2="15"></line>
              </svg>
              <span class="filtro">{{ filterStatus }}</span>
            </template>
            <b-dropdown-item
              id="filter-item"
              @click="fetchLeads('', 'a'), (itemSelected = 'circle')"
            >
              <img src="@/assets/img/icons/circle.svg" alt="dots" />
              Todos os status
            </b-dropdown-item>
            <b-dropdown-item
              id="filter-item"
              @click="fetchLeads('', 'waiting'), (itemSelected = 'poligono')"
            >
              <img src="@/assets/img/icons/poligono.svg" alt="dots" />
              Em espera
            </b-dropdown-item>
            <b-dropdown-item
              id="filter-item"
              @click="fetchLeads('', 'processing'), (itemSelected = 'square')"
            >
              <img src="@/assets/img/icons/square.svg" alt="dots" />
              Processando
            </b-dropdown-item>
            <b-dropdown-item
              id="filter-item"
              @click="fetchLeads('', 'created'), (itemSelected = 'lead-check')"
            >
              <img src="@/assets/img/icons/lead-check.svg" alt="dots" />
              Lead criado
            </b-dropdown-item>
            <b-dropdown-item
              id="filter-item"
              @click="fetchLeads('', 'updated'), (itemSelected = 'lead-update')"
            >
              <img src="@/assets/img/icons/lead-update.svg" alt="dots" />
              Lead atualizado
            </b-dropdown-item>
            <b-dropdown-item
              id="filter-item"
              @click="fetchLeads('', 'errored'), (itemSelected = 'lead-error')"
            >
              <img src="@/assets/img/icons/lead-error.svg" alt="dots" />
              Algo deu errado
            </b-dropdown-item>
          </b-dropdown>

          <BaseButton @click="resetModal('importar-dados')" variant="black">
            Importar dados
          </BaseButton>
        </div>
        <b-row class="Table-header">
          <b-col cols="1" class="d-none gap-label d-md-block">
            # id Lead
          </b-col>

          <b-col cols="2" class="d-none gap-label d-md-block">
            Nome Lead
          </b-col>

          <b-col cols="3" class="d-none gap-label d-md-block">
            Endereço de e-mail
          </b-col>
          <b-col cols="2" class="d-none gap-label d-md-block"> Telefone </b-col>
          <b-col cols="2" class="d-none gap-label d-md-block"> Status </b-col>
          <b-col cols="2" class="d-none gap-label d-md-block"> Mensagem </b-col>
        </b-row>

        <b-row v-for="(item, index) in leads" :key="index" class="Table-body">
          <b-col cols="1" class="d-none gap-label d-md-block">
            {{ item.id }}
          </b-col>

          <b-col cols="2" class="d-none gap-label d-md-block">
            {{ item.parsed_lead.name ? item.parsed_lead.name : "Sem nome" }}
          </b-col>

          <b-col cols="3" class="d-none gap-label d-md-block">
            <span v-if="item.parsed_lead && item.parsed_lead.email">
              {{ item.parsed_lead.email }}
            </span>
            <span
              v-else-if="
                item.raw_response &&
                item.raw_response.obj_lead &&
                item.raw_response.obj_lead.email
              "
            >
              {{ item.raw_response.obj_lead.email }}
            </span>
            <span v-else> Sem e-mail </span>
          </b-col>
          <b-col cols="2" class="d-none gap-label d-md-block">
            {{ item.parsed_lead.telefone ? item.parsed_lead.telefone : item.parsed_lead.phone }}
          </b-col>
          <b-col cols="2" class="d-none gap-label d-md-block">
            <span class="criado" v-if="item.status == 'created'"
              >LEAD CRIADO</span
            >
            <span class="atualizado" v-else-if="item.status == 'updated'"
              >LEAD ATUALIZADO</span
            >
            <span class="error" v-else-if="item.status == 'errored'"
              >ALGO DEU ERRADO</span
            >
          </b-col>
          <b-col cols="2" class="d-none gap-label d-md-block">
            <span
              v-if="
                item && item.raw_response && item.raw_response.msg == 'sucesso'
              "
              >{{ item.raw_response.sucesso }}</span
            >
            <span
              v-if="
                item && item.raw_response && item.raw_response.msg == 'erro'
              "
              >{{ item.raw_response.erro }}</span
            >
          </b-col>
        </b-row>
      </b-col>
      <b-row>
        <b-col>
          <Paginate
            v-if="!steps && leads && leads.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            :disabled="true"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        variant="link-info"
        v-if="steps == 1"
        class="mr-4"
        @click="cancel"
      >
        Fechar
      </BaseButton>
      <BaseButton
        v-if="steps == 1 && !importacao"
        @click="onSubmit"
        variant="primary"
        :disabled="loading"
      >
        Iniciar importação dos dados
      </BaseButton>
      <BaseButton
        v-if="steps == 2 || steps == 3"
        @click="deleteImport"
        variant="danger"
        >Cancelar</BaseButton
      >
      <BaseButton
        v-if="steps == 2"
        @click="nextStep"
        variant="primary"
        :disabled="isDuplicate"
        >Próxima etapa</BaseButton
      >
      <BaseButton v-if="steps == 3" @click="steps = 2" variant="link-info"
        >Voltar</BaseButton
      >
      <BaseButton
        @click="confirmarImportacao"
        v-if="steps == 3"
        variant="primary"
        >Confirmar importação</BaseButton
      >
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import BaseButton from "@/components/BaseButton";
import Multiselect from "vue-multiselect";
//
import ImportLeadsService from "@/services/resources/ImportLeadsService";
const serviceImportLeads = ImportLeadsService.build();
//
import RecursesService from "@/services/resources/RecursesService";
const serviceRecurses = RecursesService.build();
//
import ImportService from "@/services/resources/ImportService";
const serviceImport = ImportService.build();
//
import SellerListService from "@/services/resources/SellerListService";
const serviceSeller = SellerListService.build();
//
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();
//
import TagService from "@/services/resources/TagService";
const serviceTags = TagService.build();
//
import Vue from "vue";
import Paginate from "@/components/Paginate";

export default {
  components: {
    Multiselect,
    Paginate,
    BaseButton,
  },
  data() {
    return {
      steps: 1,
      title: "Preencha os Dados",
      max_leads: null,
      mensage: null,
      loading: false,
      lista: null,
      avancado: false,
      sellers: [],
      selectedSeller: null,
      forms: [],
      selectedForm: null,
      tags: [],
      selectedTags: [],
      search: "",
      email: null,
      files: [],
      selected: [],
      opcoes: [
        { value: "name", text: "Nome" },
        { value: "email", text: "E-mail" },
        { value: "phone", text: "Telefone" },
        { value: "adicional", text: "Adicional" },
      ],
      import: null,
      rawSample: [],
      sample: [],
      adicional: [],
      isDuplicate: false,
      leads: null,
      selects: [],
      total: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      itemSelected: "circle",
      filterStatus: "Todos os status",
      status: "",
      stops: [],
      loading_select: false,
      antiDuplicate: "",
    };
  },
  props: ["importacao"],
  computed: {
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
    fetchForms() {
      return this.$store.getters.forms;
    },
    fetchTags() {
      return this.$store.getters.tags;
    },
    removeFirst() {
      var result = [];
      this.sample.forEach((item) => {
        if (this.sample.indexOf(item) > 0) {
          result.push(item);
        }
      });
      return result;
    },
  },
  methods: {
    debounce: _.debounce(function (query) {
      this.search = query;
      this.loading_select = true;
      this.forms = [];
      this.getForms(1, query);
    }, 500),
    fetchLimit() {
      serviceRecurses.search().then((resp) => {
        this.max_leads = resp.nleads;
      });
    },
    setAdicional(content, index) {
      if (content == "name" || content == "email" || content == "phone") {
        return;
      }
      this.selected[index] = "adicional";
      // this.adicional[index] = "";
    },
    getVendedor(page = 1) {
      if (this.stops.indexOf("seller") != -1) {
        return;
      }
      var data = {
        page: page,
      };
      serviceSeller
        .search(data)
        .then((resp) => {
          let notSeller = {
            id: 0,
            email: "Sem vendedor",
          };
          // this.vendedores.unshift(notSeller);
          if (resp.data.length < resp.per_page) {
            this.stops.push("seller");
          }
          this.sellers = this.sellers.concat(resp.data);
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    canShowOption() {
      let level = this.$store.getters.user.user.level;
      if (level != "blogger" && level != "seller" && level != "templater") {
        return true;
      }
      return false;
    },
    getTags(page = 1, per_page = 20, all = false) {
      if (this.stops.indexOf("tag") != -1) {
        return;
      }

      var data = {
        id: `list?page=${page}&per_page=${per_page}`,
      };

      if (all) {
        this.tags = resp.data;

        return;
      }
      serviceTags.read(data).then((resp) => {
        if (resp.data.length < resp.per_page) {
          this.stops.push("tag");
        }

        this.tags = this.tags.concat(resp.data);
      });
    },
    onSubmit() {
      if (!this.lista) {
        this.$bvToast.toast("Por favor, preencha o campo de lista", {
          title: "Importação de leads",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }

      var tags = [];
      if (this.selectedTags) {
        for (let i = 0; i < this.selectedTags.length; i++) {
          tags.push(this.selectedTags[i].id);
        }
      } else {
        tags = "";
      }

      // if (this.avancado) {
      //   if (!this.selectedSeller) {
      //     this.$bvToast.toast("Por favor, selecione um vendedor", {
      //       title: "Importação de leads",
      //       variant: "danger",
      //       autoHideDelay: 5000,
      //       appendToast: true,
      //     });
      //     this.loading = false;
      //     return;
      //   }

      //   if (!this.selectedForm) {
      //     this.$bvToast.toast("Por favor, selecione um formulário", {
      //       title: "Importação de leads",
      //       variant: "danger",
      //       autoHideDelay: 5000,
      //       appendToast: true,
      //     });
      //     this.loading = false;
      //     return;
      //   }

      //   if (!this.selectedTags) {
      //     this.$bvToast.toast(
      //       "Por favor, selecione pelo menos uma tag",
      //       {
      //         title: "Importação de leads",
      //         variant: "danger",
      //         autoHideDelay: 5000,
      //         appendToast: true,
      //       }
      //     );
      //     this.loading = false;
      //     return;
      //   }

      //   var emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      //   if (!this.email) {
      //     this.$bvToast.toast("Por favor, preencha o campo de e-mail", {
      //       title: "Importação de leads",
      //       variant: "danger",
      //       autoHideDelay: 5000,
      //       appendToast: true,
      //     });
      //     this.loading = false;
      //     return;
      //   }

      //   if (this.email && !this.email.match(emailRegex)) {
      //     this.$bvToast.toast("Por favor, preencha um e-mail válido", {
      //       title: "Importação de leads",
      //       variant: "danger",
      //       autoHideDelay: 5000,
      //       appendToast: true,
      //     });
      //     this.loading = false;
      //     return;
      //   }
      // }

      var csv_file = document.querySelector("#csv-input");

      if (!csv_file.files[0]) {
        this.$bvToast.toast("Por favor, selecione um arquivo CSV", {
          title: "Importação de leads",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }

      var formData = new FormData();
      formData.append("file", csv_file.files[0]);
      formData.append("title", this.lista);
      formData.append("tag_ids", tags);
      formData.append(
        "form_id",
        this.selectedForm && this.selectedForm.id ? this.selectedForm.id : null
      );
      formData.append("contact_address", this.email);
      formData.append(
        "seller_id",
        this.selectedSeller && this.selectedSeller.id
          ? this.selectedSeller.id
          : null
      );

      // serviceImportLeads
      //   .create(formData)
      //   .then((resp) => {
      this.fetchImport(formData);
      this.$emit("export-leads");
      this.steps = 2;
      this.title = "Confirmar importação";
      //   })
      //   .catch((err) => {
      //     this.$bvToast.toast("Erro ao Importar leads", {
      //       title: "Importação de leads",
      //       variant: "danger",
      //       autoHideDelay: 5000,
      //       appendToast: true,
      //     });
      //     // console.log(err);
      //   })
      //   .finally(() => {});
    },
    resetModal(data) {
      this.$bvModal.hide(data);
      this.steps = 1;
      this.importacao = null;
      this.lista = null;
      this.selectedSeller = null;
      this.selectedForm = null;
      this.tags = this.fetchTags;
      this.selectedTags = null;
      this.email = null;
      this.avancado = false;
      this.adicional = [];
      this.$bvModal.show(data);
    },
    avanacdoToggle() {
      this.avancado = this.avancado == false ? true : false;
      this.selectedSeller = null;
      this.selectedForm = null;
      this.selectedTags = null;
      this.email = null;
    },
    openModal() {
      if (!this.importacao) {
        this.steps = 1;
        this.lista = null;
        this.selectedSeller = null;
        this.selectedForm = null;
        this.tags = this.fetchTags;
        this.selectedTags = null;
        this.email = null;
        this.avancado = false;
        this.adicional = [];
        return;
      }

      if (this.importacao && this.importacao.status == "done") {
        this.title = "Detalhes da Importação";
        this.fetchLeads("", "a");
        this.steps = null;
        this.itemSelected = "circle";
        this.filterStatus = "Todos os status";
      } else {
        this.steps = 2;
        this.fetchRawSample(this.importacao.id);
      }
    },
    getForms(page = 1, query = " ") {
      if (this.stops.includes("form") && query == " ") {
        return;
      }

      var data = {
        id: `?page=${page}&order_by=title&status=active&order=ASC&search=${
          query != "" ? this.search : ""
        }`,
      };

      if (this.antiDuplicate === data.id) {
        return;
      }

      this.antiDuplicate = data.id;

      serviceForm.read(data).then((resp) => {
        this.loading_select = false;

        if (resp.data.length < resp.per_page) {
          this.stops.push("form");
        }

        this.forms = this.forms.concat(resp.data);
      });
    },
    async fetchImport(formData) {
      this.loading = true;
      serviceImport.create(formData).then(async (resp) => {
        // console.log(resp);
        this.loading = false;
        this.import = await resp;
        await this.fetchRawSample(resp.id);
      });
    },
    async fetchRawSample(id) {
      this.loading = true;

      // console.log("iddd", id);
      serviceImport.read(`/${id}/rawSample`).then(async (resp) => {
        this.rawSample = await resp[1];
        this.selected = await resp[0];
        for (let i = 0; i < this.selected.length; i++) {
          this.adicional[i] = null;
        }
        if (!this.importacao) {
          this.loading = false;
          await this.fetchSample(this.import.id);
          return;
        }
        this.loading = false;
        await this.fetchSample(this.importacao.id);
      });
    },
    async fetchSample(id) {
      this.loading = true;

      serviceImport
        .read(`/${id}/sample`)
        .then(async (resp) => {
          this.loading = false;
          this.sample = await resp;
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
        });
    },
    deleteImport() {
      Vue.swal({
        title: "Deletar importação",
        text: `Deseja deletar a importação?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {};
          if (this.importacao) {
            data.id = `/${this.importacao.id}`;
          } else {
            data.id = `/${this.import.id}`;
          }

          this.loading = true;
          serviceImport
            .destroy(data)
            .finally(() => {
              this.loading = false;
              this.$emit("importar-dados");
              this.$bvModal.hide("importar-dados");
              this.$bvToast.toast("Importação deletada com sucesso", {
                title: "Importação de leads",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch(() => {
              this.$bvToast.toast("Erro ao deletar importação", {
                title: "Importação de leads",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
              this.$emit("importar-dados");
              this.$bvModal.hide("importar-dados");
            });
        }
      });
    },
    nextStep() {
      this.selects = [...this.selected];
      for (let i = 0; i < this.selects.length; i++) {
        if (this.selects[i] == "adicional") {
          this.selects[i] = this.adicional[i];
        }
      }
      for (let x = 0; x < this.adicional.length; x++) {
        const element = this.adicional[x];
        // console.log(element);
        if (
          !element &&
          this.selected[x] === "adicional" &&
          this.selected.indexOf("adicional") != -1
        ) {
          this.$bvToast.toast("Por favor, preencha o campo adicional", {
            title: "Importação de leads",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }
        if (element === "" && this.selected.indexOf("adicional") != -1) {
          this.$bvToast.toast("Por favor, preencha o campo adicional", {
            title: "Importação de leads",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
        }
      }
      var data = {};
      data.headers = this.selects;
      if (this.importacao) {
        data.id = `/${this.importacao.id}`;
      } else {
        data.id = `/${this.import.id}`;
      }
      serviceImport.createId(data).then(async () => {
        if (!this.importacao) {
          await this.fetchSample(this.import.id);
          return;
        }
        await this.fetchSample(this.importacao.id);
      });
      this.steps = 3;
    },
    confirmarImportacao() {
      var data = {
        headers: this.selects,
        status: "waiting",
      };
      if (!this.importacao) {
        data.id = `/${this.import.id}`;
        serviceImport
          .createId(data)
          .then(() => {
            this.$bvToast.toast("Importação realizada com sucesso", {
              title: "Importação de leads",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$emit("importar-dados");
            this.$bvModal.hide("importar-dados");
          })
          .catch((err) => {
            this.$bvToast.toast("Erro ao efetuar importação", {
              title: "Importação de leads",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$bvModal.hide("importar-dados");
          });
        return;
      }
      data.id = `/${this.importacao.id}`;
      serviceImport
        .createId(data)
        .then(() => {
          this.$bvToast.toast("Importação realizada com sucesso", {
            title: "Importação de leads",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$emit("importar-dados");
          this.$bvModal.hide("importar-dados");
        })
        .catch((err) => {
          this.$bvToast.toast("Erro ao efetuar importação", {
            title: "Importação de leads",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("importar-dados");
        });
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchLeads(page);
      this.itemSelected = "circle";
      this.filterStatus = "Todos os status";
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchLeads(qtd);
    },
    fetchLeads(page, status) {
      let id = this.importacao.id;

      if (status) {
        this.status = status == "a" ? "" : status;
        this.pagination.currentPage = 1;
      }

      serviceImport
        .read(
          `/${id}?page=${
            this.pagination.currentPage ? this.pagination.currentPage : 1
          }&status=${this.status ? this.status : ""}`
        )
        .then((resp) => {
          this.leads = resp.data;
          this.pagination.totalPages = resp.last_page;
          this.pagination.currentPage = resp.current_page;
          this.pagination.perPage = resp.per_page;
          this.total = resp.total;
        });
    },
  },
  created() {
    this.fetchLimit();
  },
  watch: {
    selected() {
      // console.log("teste");
      this.selected.forEach((item) => {
        if (item == null) {
          item = "adiconal";
        }
      });
      var array = [];
      this.selected.map(function (item) {
        if (item != "adicional") {
          array.push(item);
        }
      });
      var isDuplicate = array.some(function (item, idx) {
        return array.indexOf(item) != idx;
      });
      if (isDuplicate === true) {
        this.$bvToast.toast("Campos duplicados, verifique e tente novamente", {
          title: "Mensagem manual",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        this.isDuplicate = true;
        return;
      }
      this.isDuplicate = false;
    },
    itemSelected() {
      if (this.itemSelected == "circle") {
        this.filterStatus = "Todos os status";
      } else if (this.itemSelected == "poligono") {
        this.filterStatus = "Em espera";
      } else if (this.itemSelected == "square") {
        this.filterStatus = "Processando";
      } else if (this.itemSelected == "lead-check") {
        this.filterStatus = "Lead criado";
      } else if (this.itemSelected == "lead-update") {
        this.filterStatus = "Lead atualizado";
      } else {
        this.filterStatus = "Algo deu errado";
      }
    },
  },
};
</script>

<style scoped lang="scss">
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}

p + p {
  margin-top: 5px;
}

.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }

  .top-dados #limpar {
    margin-top: 15px;
  }
}

.form-relative {
  position: relative;
}

.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}

.span-csv {
  display: block;
  font-weight: bold;
  margin: 10px 0;
  font-size: 14px;
}

.avancado {
  margin-top: 20px !important;
}

.ul-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.exemplo-item {
  margin: 0;
  width: 280px;
  padding: 0;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    top: 15px;
    right: 20px;
    background: url("../../assets/img/icons/check.svg") no-repeat center center;
  }
}

.exemplo-item li {
  padding: 12px 0 !important;
  border-bottom: 1px solid #e5e5e5;
}

.exemplo-item div {
  margin: 0 30px;
}

.exemplo-item span {
  color: var(--greenn);
  font-size: 11px;
  margin: 0;
  text-transform: capitalize;
}

.exemplo-item p {
  margin: 0;
  margin-top: 5px !important;
  color: #000;
  font-weight: 600;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.atualizado {
  color: var(--bluee) !important;
}

.criado {
  color: var(--greenn) !important;
}

.error {
  color: #ff0846 !important;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.filtro {
  margin: 0 !important;
  color: var(--bluee) !important;
  align-items: center;
  transition: 0.3s ease;
  font-weight: bold;
  font-size: 14px;
}

#button-filter svg {
  color: var(--bluee) !important;
}

.Table-body {
  min-height: 0 !important;
}
</style>
