<template>
  <div class="container">
    <BaseHeader title="Listagem de importações"> </BaseHeader>

    <div class="container-table">
      <div class="box-total">
        <div class="total-vendas info"></div>
        <div>
          <p class="total-vendas">
            Total de importações:
            <span>{{ total }}</span>
          </p>
        </div>
        <div></div>
      </div>

      <div class="header-table">
        <BaseButton
          class="btn-refresh"
          variant="outline-info2"
          v-if="!isMobile"
          @click="refresh"
        >
          <img
            class="img-icon"
            src="@/assets/img/icons/refresh-ccw.svg"
            alt="icon"
          />
          Recarregar lista
        </BaseButton>

        <BaseButton
          @click="lista.length == 0 || lista[0].status == 'done' || lista[0].status == 'error' ?  openModal('importar-dados') : ''"
          variant="primary"
          v-if="!isMobile"
          v-b-tooltip.hover
          :class="lista.length == 0 || lista[0].status == 'done' || lista[0].status == 'error' ? '' : 'disabled-button'"
          :title="lista.length == 0 || lista[0].status == 'done' || lista[0].status == 'error' ? 'Importe ja seus leads!' : 'Aguarde a última importação finalizar'"
        >
          Importar dados
        </BaseButton>
      </div>

      <!-- HEADER -->
      <b-row class="Table-header mb-1" v-if="!isMobile">
        <b-col cols="1" class="d-none gap-label d-md-block"> ID </b-col>

        <b-col cols="2" class="d-none gap-label d-md-block">
          Título
        </b-col>

        <b-col cols="1" class="d-none gap-label d-md-block" v-if="!isMobile">
          Linhas
        </b-col>

        <b-col cols="1" class="d-none gap-label d-md-block" v-if="!isMobile">
          Aguardando
        </b-col>

        <b-col cols="1" class="d-none gap-label d-md-block"> Entraram </b-col>

        <b-col cols="1" class="d-none gap-label d-md-block">
          Atualizados
        </b-col>

        <b-col cols="1" class="d-none gap-label d-md-block">
          Erro
        </b-col>

        <b-col cols="2" class="d-none gap-label d-md-block"> Status </b-col>
      </b-row>
      <!-- END HEADER -->

      <!-- BODY -->
      <template v-if="!loading && lista.length > 0 && !isMobile">
        <b-row
          @click="openModal('importar-dados', item)"
          class="Table-body"
          v-for="item in lista"
          :key="item.ID"
        >
          <b-col cols="1"> {{ item.id }} </b-col>

          <b-col cols="2"> {{
                  item.title && item.title.length > 20
                    ? item.title.substr(0, 20) + "..."
                    : item.title
                }}</b-col>

          <b-col cols="1">
            <div class="count linhas">
              <span style="text-align: center" class="info-linhas">{{
                item.lines_count
              }}</span>
            </div>
          </b-col>

          <b-col cols="1">
            <div class="count aguardando">
              <span class="info-aguardando">{{
                item.waiting_lines_count
              }}</span>
            </div>
          </b-col>

          
          <b-col cols="1">
            <div class="count entraram">
              <span class="info-entraram">{{ item.created_lines_count }}</span>
            </div>
          </b-col>
          
          <b-col cols="1">
            <div class="count atualizados">
              <span class="info-atualizados">{{
                item.updated_lines_count
              }}</span>
            </div>
          </b-col>
          <b-col cols="1">
            <div class="count errado">
              <span class="info-errado">{{ item.errored_lines_count }}</span>
            </div>
          </b-col>

          <b-col cols="2">
            <span
              style="color: #f6be29 !important"
              v-if="item.status == 'processing'"
              >PROCESSANDO ARQUIVO</span
            >
            <span
              style="color: var(--bluee) !important"
              v-else-if="item.status == 'preparing'"
            >
              PREPARANDO PARA ENVIO</span
            >
            <span
              style="color: var(--greenn) !important"
              v-else-if="item.status == 'done'"
            >
              DADOS IMPORTADOS</span
            >
            <span
              style="color: #f6be29 !important"
              v-if="item.status == 'waiting'"
              >EM ESPERA</span
            >
            <span
              style="color: #f62929 !important"
              v-if="item.status == 'error'"
              >ERRO AO IMPORTAR</span
            >
          </b-col>
        </b-row>
      </template>

      <b-row>
        <b-col>
          <Paginate
            v-if="lista.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            :disabled="true"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>

      <div
        v-if="loading"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <b-row
        v-if="!loading && !lista.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhuma lista de importação foi encontrada</p>
      </b-row>
      <!-- END BODY -->
      <ImportarDados @importar-dados="fetchImport()" :importacao="importacao" />
    </div>
  </div>
</template>
<script>
import ImportService from "@/services/resources/ImportService";
import Paginate from "@/components/Paginate";
import BaseHeader from "@/components/BaseHeader";
import BaseButton from "@/components/BaseButton";
import ImportarDados from "@/components/ImportacaoLeads/ImportarDados";
import Vue from "vue";
import moment from "moment-timezone";
import _ from "lodash";
const serviceImport = ImportService.build();

export default {
  components: {
    BaseButton,
    Paginate,
    BaseHeader,
    ImportarDados,
  },
  data() {
    return {
      lista: [],
      client: {
        width: 0,
      },
      selectedHtml: "",
      total: 0,
      search: null,
      loading: true,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      importacao: null,
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
    isMobile() {
      return this.client.width <= 768;
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YY HH:ss");
    },
  },

  methods: {
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchImport(this.search);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchImport(this.search);
    },
    fetchImport(search = null) {
      this.loading = true;
      this.pagination.totalPages = 1;

      let data = {
        page: this.pagination.currentPage,
      };

      if (this.search) {
        data.search = this.search;
      }

      serviceImport
        .search(data)
        .then((response) => {
          this.lista = response.data;
          this.total = response.total;
          this.pagination.totalPages = response.last_page;
          this.pagination.currentPage = response.current_page;
          this.pagination.perPage = response.per_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refresh() {
      this.pagination.currentPage = 1;
      this.loading = true;
      serviceImport
        .search()
        .then((response) => {
          this.lista = response.data;
          this.total = response.total;
          this.pagination.totalPages = response.last_page;
          this.pagination.currentPage = response.current_page;
          this.pagination.perPage = response.per_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    debounce: _.debounce(function () {
      this.pagination.currentPage = 1;
      this.fetchImport(this.search);
    }, 500),
    filter(filters) {
      this.filters = filters;
      this.pagination.currentPage = 1;
      this.fetchImport();
    },
    openModal(data, importacao) {
      if (importacao && importacao.status != 'done') {
        if (
          // importacao.status == "waiting" ||
          // importacao.status == "processing" ||
          importacao.status == "error"
        ) {
          Vue.swal({
            title: "Importação",
            text: `Erro na importação, você deseja deletar?`,
            type: "danger",
            showCancelButton: true,
            confirmButtonText: "Deletar",
            cancelButtonText: "Cancelar",
            customClass: "sweet-container",
            confirmButtonClass: "button button-danger mt-3 mb-3",
            cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
            buttonsStyling: false,
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.loading = true;

              var data = {
                id: importacao.id,
              };
              serviceImport
                .destroy(data)
                .then(() => {
                  this.loading = false;
                  this.$bvToast.toast("Importação deletado com sucesso", {
                    title: "Importação",
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                  });
                  this.refresh();
                })
                .catch(() => {
                  this.$bvToast.toast("Erro ao deletar Importação", {
                    title: "Importação",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  });
                })
                .finally(() => {
                  this.loading = false;
                });
            }
          });

          //     this.$bvToast.toast(
          //       "Nesse status não é possível visualizar os detalhes",
          //       {
          //         title: "Importação de leads",
          //         variant: "danger",
          //         autoHideDelay: 5000,
          //         appendToast: true,
          //       }
          //     );
              return;
        }
        this.$emit("importar-dados");
        this.$bvModal.hide("importar-dados");
        this.$bvToast.toast("Continue importando seus dados de onde parou", {
          title: "Importação de leads",
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.$bvModal.show(data);
        this.importacao = importacao;
      }
      this.$bvModal.show(data);
      this.importacao = importacao;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  mounted() {
    this.loading = true;
    this.fetchImport();
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .col-1 {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .col-2 {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .col-3 {
    display: flex !important;
    flex-wrap: wrap !important;
  }
}

.break {
  word-break: break-all;
}
.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-refresh {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: var(--greenn2);
  margin-right: 15px;
}
.btn-table:first-child:active {
  background: var(--greenn2);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(3) {
  margin-left: 15px;
  margin-right: 20px;
}

.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  padding-top: 15px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datas .vue-daterange-picker {
  width: 175px;
  font-size: 14px !important;
  color: #81858e !important;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}
.search {
  position: absolute;
  left: 37px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.Table-header {
  justify-content: space-between;
  > div {
    padding: 0 10px !important;
  }
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
@media screen and (max-width: 768px) {
  .Table-header {
    gap: 1% !important;
    text-align: center;
    padding: 20px;
    border-bottom: 1px #ededf0 solid;
  }

  .Table-body {
    gap: 50px;
  }
  .header-table {
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  font-size: 13px;
  color: var(--greenn);
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 20px;
  justify-content: space-between;
  > div {
    padding: 0 10px !important;
  }
  cursor: pointer;
}
.Table-body::before {
  left: 0;
}

.title-report {
  color: var(--gray01);
  font-weight: bold;
  font-size: 20px;
}
.title-report-margin-top {
  margin-top: 41px;
}
.title-report-margin-bottom {
  margin-top: 41px;
}

.default {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: #000;
  background: #0000003b;
  border-radius: 3px;
  // white-space: nowrap;
}
.success {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: var(--greenn);
  background: var(--greenn)3b;
  border-radius: 3px;
  // white-space: nowrap;
}
div#checkbox-group-all {
  position: relative;
  left: -5px;
}
.count {
  padding: 5px !important;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
  text-align: center !important;
}
.linhas {
  background: rgba(0, 0, 0, 0.03);
}
.aguardando {
  background: rgba(255, 203, 55, 0.1);
}
.atualizados {
  background: rgba(46, 215, 238, 0.1);
}
.entraram {
  background: var(--greenn2);
}
.errado {
  background: rgba(255, 7, 69, 0.1);
}
.info-linhas {
  &::before {
    content: "";
    width: 6px;
    height: 6px;
    display: inline-block;
    background: #000;
    border-radius: 50%;
    margin-right: 10px !important;
  }
}
.info-aguardando {
  color: var(--orange);
  &::before {
    content: "";
    width: 6px;
    height: 6px;
    display: inline-block;
    background: var(--orange);
    border-radius: 50%;
    margin-right: 10px !important;
  }
}
.info-atualizados {
  color: #2ed7ee;
  &::before {
    content: "";
    width: 6px;
    height: 6px;
    display: inline-block;
    background: #2ed7ee;
    border-radius: 50%;
    margin-right: 10px !important;
  }
}
.info-entraram {
  color: var(--greenn);
  &::before {
    content: "";
    width: 6px;
    height: 6px;
    display: inline-block;
    background: var(--greenn);
    border-radius: 50%;
    margin-right: 10px !important;
  }
}
.info-errado {
  color: #ff0745;
  &::before {
    content: "";
    width: 6px;
    height: 6px;
    display: inline-block;
    background: #ff0745;
    border-radius: 50%;
    margin-right: 10px !important;
  }
}

.disabled-button{
  background: var(--gray05);
}
</style>
